:root {
    --main-bg-color: #F3F6F6;
    --dark-color: #E0E4E5;
    --text-background-color: #EDF1F1;
    --header-color: #B24B4E;
    --red-color: #B24B4E;
    --text-color: #353A4F;

    --big-font-size: 28px;
    --bigger-font-size: 30px;
    --biggest-font-size: 32px;
    --main-font-size: 20px;
    --small-font-size: 18px;
    --smaller-font-size: 14px;
    --smallest-font-size: 12px;
}

iframe {
    pointer-events: none;
    touch-action: none;
}

@media (prefers-color-scheme: dark) {
    :root {
        --main-bg-color: #1B1B3A;
        --dark-color: #2D3142;
        --text-background-color: #353A4F;
        --header-color: #A0B6A5;
        --red-color: #EF8A61;
        --text-color: #f6bd60;
    }

}

::-webkit-scrollbar {
    width: 0;
}

a.no-a, a.no-a * {
    text-decoration: none;
}

.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: space-between;
}

body {
    background: var(--main-bg-color);
    color: var(--text-color);
    font-family: 'Roboto', sans-serif !important;
    font-size: var(--main-font-size);
    font-weight: 300;
    margin: 0;
    width: 100%;
}

main {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    flex: 2;
    width: 600px;
}

h1, h2, h3 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400;
}

h4, h5, h6 {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 300;
}

h1 {
    font-size: var(--biggest-font-size);
}

h2 {
    font-size: var(--bigger-font-size);
}

h3 {
    font-size: var(--big-font-size);
}

h1, h2, h3 {
    color: var(--header-color);
}

a, a:hover {
    color: var(--red-color);
}

small {
    font-size: var(--small-font-size);
}

#variableDiv {
    display: none;
}

.mono {
    font-family: 'Roboto Mono', monospace;
}

.card-title {
    margin-top: 0;
    margin-bottom: 15px;
}

.card-title:last-child {
    margin-bottom: 0;
}

.nav.nav-pills.nav-justified {
    align-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 45px;
    justify-content: space-evenly;
    justify-items: center;
}

.nav-item {
    display: flex;
    padding: 5px;
    background: var(--text-background-color);
}

.center-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: left;
}

.nav-pills .nav-link.active {
    background-color: var(--dark-color);
    border-radius: 0;
    color: var(--header-color);
}

.active {
    color: var(--header-color);
}

.nav-pills .nav-link {
    background-color: var(--header-color);
    border-radius: 0;
    color: var(--dark-color);
    padding: 10px;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

.nav-pills .nav-link:nth-of-type(1) {
    outline: 2px solid var(--dark-color);
}

.nav-pills, .card {
    border: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border-radius: 0;
}

.separator {
    border-bottom: 4px so lid var(--dark-color);
}

.side-nav {
    font-weight: bold;
    width: 215px;
    height: 100vh;
    position: sticky;
    top: 0;
    background: var(--dark-color);
}

.side-nav li {
    list-style-type: none;
}

.side-nav > ul > li.sub-nav {
    margin: 0;
    padding: 0;
}

.side-nav > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 0;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 45px;
    font-size: var(--small-font-size);
}

.side-nav > ul > li.sub-nav > ul > li {
    font-size: var(--smaller-font-size);
    font-weight: 400;
}

.side-nav > ul > li.sub-nav > ul {
    padding-left: 15px;
}

.side-nav ul > li {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
    padding-left: 15px;
}

.top-nav-container {
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
}

.top-nav-container > nav.nav.nav-pills {
    display: flex;
    flex-direction: row;
    width: 60%;
    justify-content: space-around;
}

.parent {
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.card-deck {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.card-header, .card-footer {
    background: var(--text-background-color);
    padding: 15px;
}

.card-deck-horizontal {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.space-between {
    align-content: space-between;
    justify-content: space-between;
}

.card-deck-half {
    width: 50%;
}

.card-deck > .card:first-of-type {
    margin-top: 45px;
}

.card {
    background: var(--dark-color);
    flex: 1 1;
    margin-top: 0;
    margin-right: 15px;
    margin-left: 15px;
    min-width: 300px;
    padding: 15px;
}

.card.normal-background {
    background: unset;
}

.onclick {
    background: var(--dark-color);
    color: var(--text-color);
}

.onclick:hover {
    background: var(--header-color);
    color: var(--dark-color);
    cursor: pointer;
}

.mb-1 {
    margin-bottom: 15px;
}

.mb-3 {
    margin-bottom: 30px;
}

ul.hashtags {
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
}

ul.hashtags > li {
    color: var(--header-color);
    font-size: var(--small-font-size);
    list-style: none;
}

ul.hashtags > li > a, ul.hashtags > li > a:hover {
    color: var(--header-color);
    font-size: var(--small-font-size);
}

ul.hashtags > li > a.mono {
    font-family: 'Roboto', sans-serif !important;
}

ul.hashtags > li::before {
    content: "#";
}

ul.hashtags > li::after {
    content: "\00a0";
}

p {
    max-width: fit-content
}


@media screen and (min-width: 1100px) {
    .card {
        min-width: 800px;
    }
}

@media screen and (max-width: 1099px) {
    .card {
        min-width: 600px;
    }
}

@media screen and (max-width: 850px) and (min-width: 750px) {
    main {
        width: 400px;
    }

    .card {
        min-width: 500px;
    }
}

@media screen and (max-width: 750px) {
    main {
        flex: 1;
    }

    #variableDiv {
        display: block;
    }

    .side-nav {
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        position: sticky;
        top: 0;
        opacity: 1;
        margin-top: 0;
        height: auto;
        border-bottom: 4px solid var(--dark-color);
    }

    .side-nav > ul {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .side-nav > ul.contains-non-active {
        margin-top: 10px;
        margin-bottom: 0;
    }

    .side-nav > ul.contains-non-active > ul {
        padding-left: 0;
    }

    .side-nav > ul > li.sub-nav > ul > li.non-active {
        display: none;
    }

    .side-nav > ul > li.non-active {
        display: none;
    }

    /*noinspection ALL*/
    .top-nav-container {
        margin-top: 0;
    }

    .top-nav-container > nav.nav.nav-pills {
        width: 100%;
    }

    .parent {
        width: 100%;
    }

    .card-deck > .card:first-of-type {
        margin-top: 15px;
    }

    .card-deck-half {
        width: unset;
    }

    .card {
        min-width: 267px;
    }

    .mt-3 {
        margin-top: 30px;
    }

    .mb-3 {
        margin-bottom: 30px;
    }

    .mb-3 {
        margin-bottom: 30px;
    }

    .mb-5 {
        margin-bottom: 45px;
    }

    .hidden-mobile {
        display: none;
    }

    body {
        font-size: var(--smaller-font-size);
    }

    h1 {
        font-size: var(--bigger-font-size);
    }

    h2 {
        font-size: var(--big-font-size);
    }

    h3 {
        font-size: var(--main-font-size);
    }

    small {
        font-size: var(--smallest-font-size);
    }
}

@media screen and (max-width: 266px) {
    .card {
        font-size: small;
        min-width: 100px;
    }
}


@media (prefers-color-scheme: dark) {
    .onclick:hover {
        background: black;
    }

}